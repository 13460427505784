import conf from './services/conf/ra.json'
export const isDevelopment = () => {
    return process.env.REACT_APP_DEV_STAGE === 'development';
}

export const getPublicUrl = () => {
    return '/' + process.env.PUBLIC_URL;
}
const getAppSettings = () => {
    let res = {}

    const isDevelopment = process.env.REACT_APP_DEV_STAGE === 'development';
    if (isDevelopment)
        res = conf.dev
    else
        res = conf.prod
    res.isLocalApi = (process.env.REACT_APP_DEV_API_LOCAL === 'true');
    if (res.isLocalApi === true)
        res['api-base-url'] = "https://172.18.1.192:7092";
    return res;
}
export default getAppSettings