import LoginView from './auth/login-view';
import './ra-footer.css';

function Footer() {
    return (
        <footer>
            <div className='footer-left'>
                ra@ra.ru
            </div>
            <div className='footer-right'>
                <LoginView />
            </div>
        </footer>
    );
}

export default Footer;