import './login-callback-content.scss';

function LoginCallBackContent(props) {
    const { error } = props;
    return (
        <div className="login-callback">
            {error && <div className="login-callback-content error">
                <h3>authorization error:</h3>
                <span>{error}</span>
            </div>}
            {!error && <div className="login-callback-content">loggin in...</div>}
        </div>
    );
}

export default LoginCallBackContent;