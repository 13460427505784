import { useContext, useRef, useState } from 'react';
import './login-view.scss';
import { AuthContext } from '../../providers/auth-provider';
import { LOGIN_METHOD_OAUTH2_INTERACTIVE, LOGIN_METHOD_OAUTH2_PASSWORD } from '../../const';
import getAppSettings from '../../app-settings';
import UserManager from '../../services/user-manager';

function LoginView() {
    const { loginMethod, isAuthenticated, login, logout, interactiveLoginResponse, user } = useContext(AuthContext);
    const [showLogin, setShowLogin] = useState(false)
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);
    const [loginValid, setLoginValid] = useState(true);
    const [loginResult, setLoginResult] = useState();
    const popupRef = useRef();
    const loginForm = () => {
        return (
            <div className='login-form'>
                <form className='form' onSubmit={login_}>
                    <div className='form-field'>
                        {!loginValid && <span className='login-fld-not-valid'>*required</span>}
                        <label htmlFor='user-id'>user</label>
                        <input className={loginValid ? '' : 'not-valid'} type='text' id="user-id" value={userName}
                            onChange={handleChangeUserName} name='username' autoComplete='username' />
                    </div>
                    <div className='form-field'>
                        {!passwordValid && <span className='login-fld-not-valid'>*required</span>}
                        <label htmlFor='password-id'>password</label>
                        <input className={passwordValid ? '' : 'not-valid'} type='password' id="password-id"
                            value={password} onChange={handleChangePassword} autoComplete="current-password" name='password' />
                    </div>
                    <div className='login-result'>{loginResult}</div>
                    <div className='tool'>
                        <button type='submit'>ok</button>
                        <button type='button' onClick={cancel}>cancel</button>
                    </div>
                </form>
            </div>
        )
    }
    const formIsValid = () => {
        var isValid = true
        if (!userName) {
            setLoginValid(false);
            isValid = false;
        }
        if (!password) {
            setPasswordValid(false);
            isValid = false;
        }
        return isValid;
    }
    const login_ = (e) => {
        e.preventDefault();
        if (formIsValid())
            login({ name: userName, password: password })
                .then(() => {
                    setShowLogin(false);
                    setPassword("");
                    setUserName("");
                    setLoginResult()
                }
                )
                .catch((err) => {
                    // console.error(err);
                    setLoginResult("*invalid user name or password")
                }
                );
    }
    const handleChangeUserName = (event) => {
        setLoginValid(true);
        setLoginResult()
        setUserName(event.target.value);
    }
    const handleChangePassword = (event) => {
        setPasswordValid(true);
        setLoginResult()
        setPassword(event.target.value);
    }
    const cancel = () => {
        setUserName("");
        setPassword("");
        setShowLogin(false);
    }
    const navigateImplicit = () => {
        const appSettings = getAppSettings();
        UserManager.instance.setNewtOAuthNonceState();
        const url = `${appSettings['authority']}/authorize?client_id=ra&scope=ra&redirect_uri=${appSettings['redirect_uri']}&response_type=token&state=${UserManager.instance.oauthstate}&nonce=${UserManager.instance.oauthnonce}`;
        const ref = openPopup(url);
        popupRef.current = ref;
        window.addEventListener("message", handleOAuthMessageListener);
    }
    const handleOAuthMessageListener = (data) => {

        if (data.data && data.data.id === 'OAUTH_CALLBACK_DATA') {
            if (UserManager.instance.validateState(data.data.state)) {
                interactiveLoginResponse(data.data);
            } else {
                // console.log("state not valid ");
                const appSettings = getAppSettings();
                openPopup(`${appSettings['redirect_uri']}?error=${"error"}`);
            }
        }
    }
    const openPopup = (url) => {
        const POPUP_HEIGHT = 800;
        const POPUP_WIDTH = 500;
        const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
        const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
        // window.opener
        return window.open(
            url,
            'OAuth2 Popup',
            `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left},toolbar=no, menubar=no`
        );
    };
    const loginImplicit = () => {
        return (
            <div>
                <div className='login-link' onClick={navigateImplicit}>
                    adm
                </div>
            </div >
        )
    }
    const loginPassword = () => {
        return (
            <div>
                <div className='login-link' onClick={() => setShowLogin(!showLogin)}>
                    adm
                </div>
                {showLogin && loginForm()}
            </div>
        )
    }
    if (isAuthenticated)
        return (
            <div>
                <div className='logout' onClick={logout}>
                    {user.name}
                </div>
            </div>
        );
    else
        if (loginMethod === LOGIN_METHOD_OAUTH2_INTERACTIVE)
            return (
                loginImplicit()
            );
    if (loginMethod === LOGIN_METHOD_OAUTH2_PASSWORD)
        return (
            loginPassword()
        );
}

export default LoginView;