import './App.css';
import './App.scss';
import HomeComponent from './components/home';
import Footer from './components/ra-footer';
import Header from './components/ra-header';
import AuthProvider from './providers/auth-provider';
import { DataProvider } from './providers/data-provider';
import ServiceContainer from './services/service-container';
import history from './history';
import { getPublicUrl } from './app-settings';
import LoginCallBack from './components/auth/login-callback';
import {
  unstable_HistoryRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
export const servicesContainer = new ServiceContainer();
function App() {
  return (
    <div className="App">
      <div className='app-content'>
        <Router basename={getPublicUrl()} history={history}>
          <AuthProvider>
            <DataProvider >
              <Header />
              <main>
                <Routes>
                  <Route path="/" element={<HomeComponent />}>
                  </Route>
                  <Route path="/login-callback" element={<LoginCallBack />}>
                  </Route>
                </Routes>
              </main>
              <Footer />
            </DataProvider>
          </AuthProvider>
        </Router>
      </div>
    </div>
  );
}
export default App;
