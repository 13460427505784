import './article-lib.css';
import logo from '../media/img/logo192.png';
import { useContext } from 'react';
import { AuthContext } from '../providers/auth-provider';

function LibArticle(props) {
  const { editMode } = useContext(AuthContext)
  const { isSelected, onClick, id, data, isTool,editOnClick} = props;

  return (
    <article className={`article-lib ${isSelected ? "selected" : undefined} ${isTool ? "tool" : undefined}`}>
      <div className='article-lib-inner'>
        <div className='article-lib-top' onClick={() => onClick(id)}>
          <div>{data.Title}</div>
          {!isTool &&<img className='article-icon' src={logo} alt='' />}
        </div>
        {editMode && !isTool && <div className='edit-article-btn' onClick={()=>editOnClick(id)}>edit</div>}
      </div>
    </article>
  );
}

export default LibArticle;
