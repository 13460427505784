import ApiAuthService from './api-auth-service';
import ApiService from './api-service';
import AuthService from './auth-service';
import UserManager from './user-manager';
// import AuthService from './auth-service';
// import UserManager from './user-manager';

export class ServiceContainer {

    constructor() {
        this.apiAuthService = new ApiAuthService();
        this.apiService = new ApiService();
        this.userManager = new UserManager();
        this.authService = new AuthService();
        // this.refService = new RefService();
        // this.signalService = new TSSignal();
        // this.serviceSignal = new ServiceSignal();
        if (ServiceContainer.instance)
            return ServiceContainer.instance
        else
            ServiceContainer.instance = this;
    }
    init() {

        console.log("ServiceContainer init");
    }

}
export default ServiceContainer