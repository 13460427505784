
import axios from 'axios';

import getAppSettings from '../app-settings.js'

export default class ApiAuthService {
    constructor() {
        this.identityMetadata = { token_endpoint: "", userinfo_endpoint: "" };
        this.authority = getAppSettings()['authority'];
        this.client = axios.create({
            baseURL: this.authority,
        });
        this.access_token = '';
        this.access_token_expires_in = 0;
        this.access_token_expires_in_date_time = new Date();
        this.initToken();
        if (ApiAuthService.instance)
            return ApiAuthService.instance
        else
            ApiAuthService.instance = this;
    }
    async initToken() {
        return new Promise(async (resolve, reject) => {
            await this.getMetadata().then(async () => {
                await this.getToken().then(
                    (resp) => {
                        resolve(resp);
                    }
                ).catch(
                    (err) => {
                        reject(err);
                    }
                );
            }
            );
        });
    }
    initTokenClear() {
        return new Promise((resolve, reject) => {
            this.getMetadata().then(() => {
                this.getTokenClear().then(
                    (resp) => {
                        // console.log(resp);
                        resolve(resp);
                    }
                ).catch(
                    (err) => {
                        reject('+++++++++++++++++++++', err);
                    }
                );
            }
            );
        });
    }
    async getMetadata() {

        return new Promise(async (resolve, reject) => {
            await this.client.get(this.authority + '/.well-known/openid-configuration')
                .then(response => {
                    // if (isDevelopment())
                        this.identityMetadata.token_endpoint = response.data.token_endpoint;
                    // else
                    //     this.identityMetadata.token_endpoint = "https://ts.isvol.ru/ids4_4/connect/token";

                    this.identityMetadata.userinfo_endpoint = response.data.userinfo_endpoint;

                    resolve(response.data.token_endpoint);

                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                });
        })

    }
    async getToken() {
        const params = new URLSearchParams();
        const appSettings = getAppSettings();
        params.append('client_id', appSettings['client_id']);
        params.append('scope', appSettings['scope']);
        params.append('grant_type', appSettings['grant_type']);
        params.append('client_secret', appSettings['client_secret']);
        return new Promise(async (resolve, reject) => {
            await this.client.post(this.identityMetadata.token_endpoint,
                params
                , {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then(response => {
                    this.access_token = 'Bearer ' + response.data.access_token;
                    this.access_token_expires_in = response.data.expires_in;
                    this.access_token_expires_in_date_time = new Date();
                    this.access_token_expires_in_date_time.setSeconds(this.access_token_expires_in_date_time.getSeconds() + this.access_token_expires_in)
                    // console.log('new token', "ok",this.access_token);
                    resolve(this.access_token);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });

    }
    getTokenClear() {
        const params = new URLSearchParams();
        const appSettings = getAppSettings();
        params.append('client_id', appSettings['client_id']);
        params.append('scope', appSettings['scope']);
        params.append('grant_type', appSettings['grant_type']);
        params.append('client_secret', appSettings['client_secret']);
        return new Promise(async (resolve, reject) => {
            await this.client.post(this.identityMetadata.token_endpoint,
                params
                , {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then(response => {
                    resolve(response.data.access_token);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });

    }
    async updateTokenExpired() {
        return new Promise(async (resolve, reject) => {
            console.log("updateTokenExpired");

            await this.initToken()
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err);
                });

        });
    }
    tokenExpired = () => {
        if (this.access_token === '' ||
            (this.access_token_expires_in & this.access_token_expires_in_date_time < Date.now()))
            return true;
        else
            return false;
    }
    logoutUser() {
        console.log("logout not defined");
    }

}