import React, { Component } from "react";
import ApiService from "../services/api-service";

export const DataContext = React.createContext();
export const DataConsumer = DataContext.Consumer;
export class DataProvider extends Component {
    state = {
        articlesHead: [],
        articlesCache: [],
        article: undefined
    };

    async componentDidMount() {
        await this.initArticlesHead();
    }
    componentWillUnmount() {
    }

    async initArticlesHead() {
        const data = await ApiService.instance.getArticles("enabled");
        // console.log(data.data);
        if (Array.isArray(data.data))
            this.setState({ articlesHead: data.data.sort((a,b)=>a.Order-b.Order) });
    }
    async initArticles() {
        const data = await ApiService.instance.getArticles("enabled");
        // console.log(data.data);
        if (Array.isArray(data.data))
            this.setState({ articlesHead: data.data.sort((a,b)=>a.Order-b.Order) });
    }
    async initArticle(id) {
        const local = this.state.articlesCache.find(x => x.Id === id);
        if (local)
            this.setState({ article: local });
        else {
            const newArticleResponse=await ApiService.instance.getArticleById(id);
            // console.log(newArticleResponse);
            if (newArticleResponse.data){
                // [].sort((a,b)=>a.Order-b.Order)
                this.state.articlesCache.push(newArticleResponse.data);
                this.setState({article:newArticleResponse.data});
            }
        }
    }
    render() {
        const articlesHead = this.state.articlesHead;
        const article = this.state.article;
        const initArticle = (id) => this.initArticle(id);
        const initArticlesHead = () => this.initArticlesHead();
        return <DataContext.Provider value={{
            article,
            articlesHead,
            initArticle,
            initArticlesHead,
        }}>
            {this.props.children}
        </DataContext.Provider>;
    }
}