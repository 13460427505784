import UserManager from "./user-manager";

export default class AuthService {
    UserManager;
    constructor() {
        if (AuthService.instance)
            return AuthService.instance
        else
            AuthService.instance = this;
        this.UserManager = UserManager.instance;
        this.UserManager.startProcessing = () => this.startProcessing();
        this.UserManager.stopProcessing = () => this.stopProcessing();
        this.tokenExpired = () => this.UserManager.tokenExpired()
        this.jwtLogin = () => this.UserManager.jwtLogin()
    }
    logout() {
        return this.UserManager.logout();
    }
    stopProcessing() {
        console.log("used in authContext");
    }
    startProcessing() {
        console.log("used in authContext");
    }
    startInitialLogin() {
        // console.log('init login',);
        return new Promise(async (resolve, reject) => {
            await this.UserManager.startInitialLogin()
                .then(async (result) => {
                    // console.log('init login',this.UserManager.user);
                    await this.userLoggedIn(this.UserManager.user);
                    await this.userLoggedInSignal(this.UserManager.user);
                    resolve(this.UserManager.user)

                })
                .catch((err) => {
                    reject(false)
                });
        })
    }

    async login(user) {
        return new Promise(async (resolve, reject) => {
            await this.UserManager.login(user)
                .then(async (result) => {
                    // await this.userLoggedIn(this.UserManager.user);
                    // await this.userLoggedInSignal(this.UserManager.user);
                    // console.log("result", result);
                    // console.log(this.UserManager.user);
                    resolve(this.UserManager.user)
                })
                .catch((err) => {
                    reject(false)
                });
        })
    }
    async userLoggedIn(user) {
        console.log("auth SRV userLoggedIn", user);
    }
    async userLoggedInSignal(user) {
        console.log("auth SRV userLoggedInSignal", user);
    }
    async onInitLogin() {
        this.onInitLoginAfter();
        await this.userLoggedInSignal(this.UserManager.user);

        // console.log("async onInitLogin(){");
        await this.userLoggedIn(this.UserManager.user);
    }
    async onInitLoginAfter() {
        console.log("used in authContext");
    }
    async onLogoutEventReceived() {
        console.log("used in authContext onLogoutEventReceived");
    }
}