import { useEffect, useState } from "react";
import LoginCallBackContent from "./login-callback-content";
import React from "react";

function LoginCallBack() {
    const [error, setError] = useState();
    const parameters = new URLSearchParams(window.location.search);
    useEffect(() => {

        if (parameters.get("error"))
        // eslint-disable-next-line 
        {
            setError(parameters.get("error"))
            return;
        }
        const objPArameters = Object.fromEntries(parameters.entries());
        objPArameters.id = 'OAUTH_CALLBACK_DATA';
        if (window.opener && !parameters.has("error")) {
            window.opener.postMessage(objPArameters);
            window.close();
        }

    },
        // eslint-disable-next-line 
        []);
    return (
        <LoginCallBackContent error={error} />
    )

}

export default LoginCallBack;