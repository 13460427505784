import { useContext, useEffect } from 'react';
import './article-lib-content.scss';
import { useState } from 'react';
import { DataContext } from '../providers/data-provider';
import LibArticleEdit from './edit/article-lib-new';

function LibArticleContent(props) {
  const { id, editArticle, callBack } = props
  const { article, initArticle } = useContext(DataContext)

  const [currentPage, setCurrentPage] = useState();
  useEffect(() => {
    if (id)
      initArticle(id);
    // eslint-disable-next-line 
  }, [id]);
  useEffect(() => {
    if (article && article.Pages && Array.isArray(article.Pages)) {
      setCurrentPage(article.Pages[0])
    }
    else 
    setCurrentPage(undefined)
  }, [article]);
  const paragraphs = (val) => {
    if (val)
      return val.split("\r\n");
    else return [];
  }
  if (editArticle === true) {
    return (
      <aside className='article-lib-content'>
        <LibArticleEdit data={article} callBack={callBack} />
      </aside>)
  }
  if (article && editArticle !== true)
    return (
      <aside className='article-lib-content'>
        <div className='title'>
          {article.Title}
        </div>
        <div className='content'>
          <div className='page-title'>{currentPage ? currentPage.Title : ""}</div>
          {currentPage && Array.isArray(currentPage.Paragraphs) && currentPage.Paragraphs.map((val, key) => {
            return (
              <div key={key} className='content-paragrarh'>
                {val.Title && <div className='content-paragrarh-title'>{val.Title}</div>}
                <div style={{ display: 'flex', flexDirection: 'column', gap: "6px" }}>
                  {val.Text && paragraphs(val.Text).map((par_taxt, par_key) => {
                    return (
                      <div key={par_key} className='content-paragrarh-text'>{par_taxt}</div>
                    )
                  })}
                </div>
              </div>
            )
          }

          )}

        </div>
        <div className='footer'>
          {currentPage ? currentPage.isFirst : false}
          {currentPage ? currentPage.isLast : false}
        </div>
      </aside>

    );
  else return (
    <aside className='article-lib-content'>
      <div className='title'>
      </div>
      <div className='content'>
      </div>
      <div className='footer'>
      </div>
    </aside>
  )
}

export default LibArticleContent;
