import { useContext, useMemo, useState } from 'react';
import './home.css';
import LibArticle from './article-lib';
import LibArticleContent from './article-lib-content';
import { DataContext } from '../providers/data-provider';
import { AuthContext } from '../providers/auth-provider';

function HomeComponent() {
  const { editMode } = useContext(AuthContext)
  const { articlesHead } = useContext(DataContext)
  const [aciveArticle, setActiveArticle] = useState()
  const [showEditArticle, setShowEditArticle] = useState(false)

  const articleOnClick = (val) => {
    setShowEditArticle(false);
    setActiveArticle(val);
  }
  const addNewOnClick = () => {
    setShowEditArticle(true);
    setActiveArticle(undefined);
  }
  const editOnClick = (val) => {
    setActiveArticle(val);
    setShowEditArticle(true);
  }
  const addNewCallBack=(val)=>{
    console.log(val);
  }
  const articles =useMemo(()=>articlesHead,[articlesHead])
  return (
    <section className="home">
      <section className='lib-articles'>
        {editMode&&<LibArticle isTool={true} onClick={addNewOnClick} id={"add"} isSelected={false} data={{Title:"add new"}} key={0}/>}
        {articles.map((item, key) => {
          return (<LibArticle onClick={articleOnClick} editOnClick={editOnClick} id={item.Id} isSelected={aciveArticle === item.Id} data={item} key={key} />)
        })}

      </section>
      <LibArticleContent id={aciveArticle} editArticle={showEditArticle} callBack={addNewCallBack} />
    </section>
  );
}

export default HomeComponent;
