import './btn.scss';


function RABtn(props) {
  const { label, onClick, type } = props;

  return (
    <button className='ra-btn' onClick={onClick} type={type} >{label}</button>
  );
}

export default RABtn;
