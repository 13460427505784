import './input-checkbox.scss';


function InputCheckBox(props) {
  const { label, value, valueOnChange } = props;
  return (
    <div className='input-checkbox'>
      <input type="checkbox" checked={value??false}  readOnly={true}/>
      <label onClick={valueOnChange}>
        {<svg className='svg' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="none">
          <rect className='rect' x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="currentColor" stroke="currentColor" />
          <path className='path' d="M16.5 5.00002L6.99999 15L3.49999 11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>}
        {label && <span >{label}</span>}
      </label>
    </div>
  );
}

export default InputCheckBox;
