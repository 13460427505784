import React, { Component } from "react";
import AuthService from "../services/auth-service";
import {  OAUTH_INTERACIVE_RESPONSE_TYPE_TOKEN } from "../const";
import UserManager from "../services/user-manager";
export const AuthContext = React.createContext();

export const AuthConsumer = AuthContext.Consumer;
export class AuthProvider extends Component {
    state = {
        user: undefined,
        processing: false,
    }
    constructor() {
        super();
        this.interactiveLoginResponse = this.interactiveLoginResponse.bind(this);
    }
    login = (userObj) => {
        return new Promise(async (resolve, reject) => {
            AuthService.instance.login(userObj)
                .then((resp) => {
                    this.setState({ user: resp })
                    resolve(true);
                })
                .catch((err) => {
                    reject(err)
                })
        });
    }
    logout = () => {
        AuthService.instance.logout()
            .then(
                (user) => {
                    this.setState({ user: user })
                })
            .catch((err) => {
                console.log(err);
                this.setState({ user: {} })
            });
    }

    implicitFlowResponse(data) {
        UserManager.instance.processImplicitFlowResponse(data)
            .then((user) => {
                this.setState({ user: user })
            })
            .catch((err) => {
                console.log(err);
            });
    }
    interactiveLoginResponse(data) {
        if (UserManager.instance.oauthInteractiveResponseType=== OAUTH_INTERACIVE_RESPONSE_TYPE_TOKEN)
            this.implicitFlowResponse(data)
    }
    render() {
        const login = (f) => this.login(f);
        const logout = this.logout;
        const interactiveLoginResponse = this.interactiveLoginResponse;
        const isAuthenticated = this.state.user?.isAuthenticated;
        const loginMethod = UserManager.instance.loginMethod;
        const oauthInteractiveResponseType = UserManager.instance.oauthInteractiveResponseType;
        const user = this.state.user;
        const editMode = this.state.user && this.state.user.hasEditRole();

        return <AuthContext.Provider value={{
            login
            , logout
            , interactiveLoginResponse
            , isAuthenticated
            , loginMethod
            , oauthInteractiveResponseType
            , user
            , editMode
        }}>{this.props.children}</AuthContext.Provider>;
    }
}
export default AuthProvider