import getAppSettings from "../app-settings";
import axios from 'axios';
import ApiAuthService from "./api-auth-service";

export default class ApiService {
    constructor() {
        this.initConfig()
        this.apiAuthService = ApiAuthService.instance;
        this.state = 'ok';
        this.isError = false;
        this.errorMessage = '';
        this.initAxiosInterceptor();
        this.getArticles();
        if (ApiService.instance)
            return ApiService.instance
        else
            ApiService.instance = this;
    }
    initConfig() {
        const appSettings = getAppSettings();
        // console.log(appSettings);
        this.baseUrl = appSettings['api-base-url'];
        this.apiClient = axios.create({
            baseURL: this.baseUrl,
        });
    }
    initAxiosInterceptor() {
        const apiClient = this.apiClient;
        apiClient.interceptors.request.use((request) => {
            this.riseProcessing(true);

            request.headers["Content-Type"] = 'application/json';
            request.headers.Authorization = `${this.apiAuthService.access_token}`;
            return request;
        });
        apiClient.interceptors.response.use(
            (response) => {
                this.riseOkLocal();
                this.riseProcessing(false);
                return response;
            },
            (err) => {
                const originalRequest = err.config
                this.riseProcessing(false);
                console.log('--------------------AxiosInterceptor err-----------------------------------------');//52824
                if (err.code === "ERR_NETWORK") {
                    console.log(err);
                    this.riseErrorLocal(err);
                    return Promise((resolve, reject) => {
                        reject(err);
                    });
                }

                if (err.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = 1;
                    console.log('401 resolving');
                    // console.log(err);
                    return new Promise((resolve, reject) => {
                        this.apiAuthService.updateTokenExpired().then(
                            async () => {
                                this.riseProcessing(true);
                                apiClient(originalRequest, err.config)
                                    .then((resp) => {
                                        // console.log('401 resolved', resp);
                                        resolve(resp);
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        this.riseErrorLocal(err);
                                        this.riseProcessing(false);

                                        reject(err)
                                    });

                            }
                        )
                            .catch(
                                (err) => {
                                    console.log('updateTokenExpired catch: ' + err);
                                }
                            );
                    });

                }
                else {
                    return new Promise((resolve, reject) => {
                        this.riseErrorLocal(err);
                        console.log('final reject')
                        console.log(err);
                        reject(err);
                    });
                }

            }
        );


    }
    async getArticles(filter) {
        var data = {};
        await this.apiClient.get(`/wiki/articles-head/${filter}`)
            .then(response => {
                // console.log(response);
                data = new DataResponse(response.data);
            })
            .catch(err => {
                const resp = new DataResponse();
                resp.isError = true;
                resp.message = err;
                data = resp;
                console.log(err);
            });
        return data;
    }
    async getArticleById(id) {
        var data = {};
        await this.apiClient.get(`/wiki/articles/${id}`)
            .then(response => {
                // console.log(response);
                data = new DataResponse(response.data);
            })
            .catch(err => {
                const resp = new DataResponse();
                resp.isError = true;
                resp.message = err;
                data = resp;

            });
        return data;
    }
    async postArticle(val){
        var data = {};
        await this.apiClient.post(`/wiki/articles`,val)
            .then(response => {
                // console.log(response);
                data = new DataResponse(response.data);
            })
            .catch(err => {
                const resp = new DataResponse();
                resp.isError = true;
                resp.message = err;
                data = resp;

            });
        return data;
    }
    riseProcessing(val) {

    }
    riseErrorLocal(val) {

    }
    riseOkLocal(val) {

    }
}
export class DataResponse {
    constructor(obj) {
        if (obj) {
            this.data = obj.Data;
            this.message = obj.Message;
            this.isError = obj.IsError;
        }
        else {
            this.isError = false;
            this.message = '';
            this.data = {};
        }
    }
    fromObject(obj) {
        this.data = obj.Data;
        this.message = obj.Message;
        this.isError = obj.IsError;
    }
}