import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoginCallBack from './components/auth/login-callback';

if (window.location.pathname === '/login-callback') {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <>
      <LoginCallBack />
    </>
  );
}
else{
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <>
      <App />
    </>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
