import './ra-header.css';
import logo from '../media/img/logo192.png'; 
import { useContext } from 'react';
import { DataContext } from '../providers/data-provider';

function Header() {
  const { initArticlesHead } = useContext(DataContext)
    return (
        <header>
            <div className='nav-left'>
                <div className='nav-item nav-brand' onClick={initArticlesHead}>
                    react-arena
                </div>

            </div>
            <div className='nav-right'>
                <div className='nav-item  nav-logo'>
                    <img src={logo} alt=''/>
                </div>
            </div>

        </header>
    );
}

export default Header;