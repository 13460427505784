import { createBrowserHistory } from 'history';
import { getPublicUrl } from './app-settings';

const history = createBrowserHistory({
    basename:getPublicUrl(),
    getUserConfirmation(message, callback) {
        // Show some custom dialog to the user and call
        // callback(true) to continue the transiton, or
        // callback(false) to abort it.
        <div>da</div>
      }
});
export default history;