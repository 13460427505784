export const localStoragePrefix="ra";   
export const LOGIN_METHOD_OAUTH2_INTERACTIVE='INTERACTIVE'
export const LOGIN_METHOD_OAUTH2_PASSWORD='PASSWORD'

export const OAUTH_INTERACIVE_RESPONSE_TYPE_CODE='CODE'
export const OAUTH_INTERACIVE_RESPONSE_TYPE_TOKEN='TOKEN'

export const VALIDATION_FLD_REQUIRED='*field required'


