import axios from 'axios';
import getAppSettings from '../app-settings';
import { LOGIN_METHOD_OAUTH2_INTERACTIVE, OAUTH_INTERACIVE_RESPONSE_TYPE_TOKEN, localStoragePrefix } from '../const';
export default class UserManager {
    constructor() {
        if (UserManager.instance)
            return UserManager.instance
        else
            UserManager.instance = this;
        this.initConfig()
        this.identityMetadata = { token_endpoint: "", userinfo_endpoint: "" };

        this.user = new User();
        this.isError = "";
        this.oauthstate = undefined;
        this.oauthnonce = undefined;
        this.loginMethod = LOGIN_METHOD_OAUTH2_INTERACTIVE;
        this.oauthInteractiveResponseType = OAUTH_INTERACIVE_RESPONSE_TYPE_TOKEN;
        this.message = "";
        this.innerMessage = "";
        this.user.loadFromStorage();
        this.refresh_token = '';
        this.access_token = '';
        this.access_token_expires_in_date_time = new Date();
    }
    async initConfig() {
        const appSettings = getAppSettings();
        // console.log(appSettings);
        this.authority = appSettings['authority'];
        this.apiClient = axios.create({
            baseURL: this.authority,
        });
        await this.getMetadata();
    }
    setOAuthState(val) {
        if (val)
            UserManager.instance.oauthstate = val;
        else
            UserManager.instance.oauthstate = Date.now();
    }
    setOAuthNonce(val) {
        if (val)
            UserManager.instance.oauthnonce = val;
        else
            UserManager.instance.oauthnonce = Date.now();
    }
    setNewtOAuthState() {
        this.setOAuthState(Date.now());
    }
    setNewtOAuthNonce() {
        this.setOAuthNonce(Date.now());
    }
    setNewtOAuthNonceState() {
        this.setNewtOAuthNonce();
        this.setNewtOAuthState();
    }
    validateState(val) {
        return String(val) === String(this.oauthstate);
    }
    tokenExpired() {
        if (this.refresh_token === '')
            return undefined
        if (!this.access_token_expires_in_date_time)
            return true;
        const DateExp = Math.floor(new Date(this.access_token_expires_in_date_time).getTime() / 1000);
        const DateCur = Math.floor(new Date().getTime() / 1000);
        const DatDif = Math.floor((DateExp - DateCur) / 60);
        if (DatDif <= 3) {
            // console.log('update now', DatDif);
            // console.log('refreshToken', this.refresh_token);
            return true
        }
        else
            return false
    }
    async login(user) {
        return new Promise(async (resolve, reject) => {
            await this.getMetadata()
                .then(async () => {
                    await this.getToken(user.name, user.password)
                        .then(
                            (resp) => {
                                this.getUserInfo()
                                    .then(
                                        (userResponse) => {
                                            // console.log("userResponse",userResponse);
                                            this.user = new User(userResponse.data)
                                            resolve(true);
                                        }
                                    )
                                    .catch(
                                        (userError) => {
                                            reject(userError);
                                        }
                                    )
                            }
                        )
                        .catch(
                            (err) => {
                                reject(err);
                            }
                        );
                }
                )
                .catch((err) => {
                    reject(err)
                });
        });
    }
    async refreshToken() {
        return new Promise(async (resolve, reject) => {
            this.startProcessing();
            if (!this.refresh_token)
                reject("refresh token undefined")
            else {
                await this.apiClient.post('Auth/RefreshToken',
                    this.refresh_token
                )
                    .then(async (response) => {
                        const data = response.data.data;
                        if (response.data.isError) {
                            this.isError = true;
                            this.message = "loginErrorMessage";
                            this.innerMessage = response.data.message;
                            reject(new Error(response.data.message));
                        }
                        else {
                            this.refresh_token = data.refreshToken
                            this.access_token = data.authToken;
                            // console.log(this.access_token);
                            this.access_token_expires_in_date_time = data.expiresIn;
                            // if (!(this.user?.isAuthenticated && this.user.id === data.user.id)) {
                            const rm = this.user.remember;
                            this.user = new User(data.user);
                            // console.log(data.user);
                            this.user.isAuthenticated = true;
                            this.user.remember = rm;
                            this.isError = false;
                            this.message = "";
                            this.innerMessage = "";
                            this.user.saveToStorage();
                            // }
                            this.apiClient.defaults.headers.ElmaAuthorization = 'Bearer ' + (this.access_token ? this.access_token : "not-defined");
                            localStorage.setItem(localStoragePrefix + 'at',
                                JSON.stringify(
                                    {
                                        "refresh_token": this.refresh_token,
                                    }));
                            this.stopProcessing();
                            resolve(true);
                        }
                    })
                    .catch(err => {
                        this.stopProcessing();
                        reject(err);
                    });
            }
        });


    }
    async checkLocalStorageToken() {
        return new Promise(async (resolve, reject) => {
            const tokenItem = localStorage.getItem(localStoragePrefix + 'at');
            let token = undefined;
            if (tokenItem) {
                try {
                    token = JSON.parse(tokenItem);
                }
                catch (err) {
                    console.log(err);
                    token = undefined;
                }
            }
            if (token && token.refresh_token) {
                this.refresh_token = token.refresh_token;
                await this.refreshToken().then(
                    () => {
                        resolve(this.user);
                    }
                ).catch(
                    (err) => {
                        reject("refreshToken", err);
                    }
                );
            }

        });
    }
    jwtLogin() {
        return new Promise(async (resolve, reject) => {
            await this.refreshToken().then(
                () => {
                    resolve(this.user);
                }
            ).catch(
                (err) => {
                    reject("refreshToken", err);
                }
            );
        });
    }
    logout() {
        this.isAuthenticated = false;
        this.user.name = '';
        this.user.password = '';
        this.user.isAuthenticated = false;
        this.user.id = '';
        this.user.remember = false;
        return new Promise(async (resolve, reject) => {
            resolve(this.user)
        });

        // this.user.deleteFromStorage();
        // this.afterLogout();
    }
    async getMetadata() {

        return new Promise(async (resolve, reject) => {
            await this.apiClient.get(this.authority + '/.well-known/openid-configuration')
                .then(response => {
                    // if (isDevelopment())
                    this.identityMetadata.token_endpoint = response.data.token_endpoint;
                    // else
                    //     this.identityMetadata.token_endpoint = "https://ts.isvol.ru/ids4_4/connect/token";

                    this.identityMetadata.userinfo_endpoint = response.data.userinfo_endpoint;

                    resolve(response.data.token_endpoint);

                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                });
        })

    }
    async initToken() {
        return new Promise(async (resolve, reject) => {
            await this.getMetadata().then(async () => {
                await this.getToken().then(
                    (resp) => {
                        resolve(resp);
                    }
                ).catch(
                    (err) => {
                        reject(err);
                    }
                );
            }
            );
        });
    }
    async getToken(username, password) {
        const params = new URLSearchParams();
        const appSettings = getAppSettings();
        params.append('client_id', appSettings['client_id']);
        params.append('scope', appSettings['user-scope']);
        params.append('grant_type', appSettings['user-grant_type']);
        params.append('username', username);
        params.append('password', password);
        return new Promise(async (resolve, reject) => {
            await this.apiClient.post(this.identityMetadata.token_endpoint,
                params
                , {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })
                .then(response => {
                    this.access_token = 'Bearer ' + response.data.access_token;
                    this.access_token_expires_in = response.data.expires_in;
                    this.access_token_expires_in_date_time = new Date();
                    this.access_token_expires_in_date_time.setSeconds(this.access_token_expires_in_date_time.getSeconds() + this.access_token_expires_in)
                    // console.log('new token', "ok", this.access_token);
                    resolve(this.access_token);
                })
                .catch(err => {
                    // console.log(err);
                    reject(err);
                });
        });

    }
    async getUserInfo() {
        return new Promise(async (resolve, reject) => {
            await this.apiClient.get(this.identityMetadata.userinfo_endpoint, { headers: { "Authorization": this.access_token } })
                .then(response => {
                    // console.log(response);
                    resolve(response);
                })
                .catch(err => {
                    // console.log(err);
                    reject(err);
                });
        });

    }
    ///////////////
    /// implicit flow
    processImplicitFlowResponse(data) {
        this.access_token = `${data.token_type} ${data.access_token}`
        return new Promise(async (resolve, reject) => {
            this.getUserInfo()
                .then(
                    (userResponse) => {
                        // console.log("userResponse",userResponse);
                        this.user = new User(userResponse.data)
                        resolve(this.user);
                    }
                )
                .catch(
                    (userError) => {
                        reject(userError);
                    }
                )
        });
    }
    /////////////////
    async afterLogin() {
        console.log("async afterLogin() {");
    }
    async afterLogout() {

    }
    async startInitialLogin() {
        return await this.checkLocalStorageToken()
    }
    startProcessing() {
        console.log("used in authSRV/authContext start");
    }
    stopProcessing() {
        console.log("used in authSRV/authContext stop");
    }
    errorMessage() {
        return this.message;
    }
    errorMessageInner() {
        return this.innerMessage;
    }
}
export class User {
    constructor(obj) {
        if (obj) {
            this.name = obj.user_name;
            this.fullName = obj.fullName;
            this.preferred_username = obj.preferred_username;
            this.id = obj.sub;
            this.isAuthenticated = true;
            this.remember = obj.remember;
            this.roles = obj.role;
        }
        else {
            this.name = '';
            this.fullName = '';
            this.preferred_username = "";
            this.id = '';
            this.isAuthenticated = false;
            this.remember = false;
            this.roles = undefined;
        }
    }
    hasEditRole() {
        return this.isInRole("Admin")
    }
    isInRole(role) {
        return this.roles ? !!(this.roles.find(x => x === role)) : false;
    }
    isInRoles(roles) {
        return this.roles && roles ? !!(this.roles.some(x => roles.indexOf(x) !== -1)) : false;
    }
    hasAdminRole() {
        return this.roles ? !!(this.roles.find(x => x === "roleAdmin")) : false;
    }
    loadFromStorage() {
        let usr = JSON.parse(localStorage.getItem(localStoragePrefix + 'u-id'));
        if (usr) {
            this.name = usr.name;
            this.id = usr.id;
            this.remember = usr.remember;
            this.useInitialLogin = usr.useInitialLogin;
        }
    }
    saveToStorage() {
        localStorage.setItem(localStoragePrefix + 'u-id', JSON.stringify({ name: this.name, id: this.id, remember: this.remember, useInitialLogin: this.useInitialLogin }));
    }
    deleteFromStorage() {
        localStorage.removeItem(localStoragePrefix + 'u-id');
    }

    shortName() {
        if (this.fullName) {
            const a = this.fullName.split(' ');
            if (a.length === 3)
                return (a[0] ? a[0] : ' ') + ' ' + (a[1] ? a[1][0] + '. ' : ' ') + (a[2] ? a[2][0] + '. ' : ' ');
            if (a.length === 4)
                return (a[0] ? a[0] : ' ') + ' ' + (a[2] ? a[2][0] + '. ' : ' ') + (a[3] ? a[3][0] + '. ' : ' ');
            return this.fullName;
        }
        else
            return 'n/a';
    }

}