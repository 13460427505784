import './input-fld.scss';


function Inputfield(props) {
  const { label, value, valueOnChange, type, validation } = props;

  return (
    <div className='input-fld'>
      <div style={{display:"flex",justifyContent:"space-between",alignItems:'center'}}>
        {label && <label>{label}</label>}
        {validation && <span className='fld-validation'> {validation} </span>}
      </div>
      <input className={`${validation?"not-valid":undefined}`} type={type} value={value} onChange={valueOnChange} />
    </div>
  );
}

export default Inputfield;
