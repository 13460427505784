import { useEffect, useState } from 'react';
import Inputfield from '../ui/input-fld';
import './article-lib-new.scss';
import InputCheckBox from '../ui/input-checkbox';
import RABtn from '../ui/btn';
import { VALIDATION_FLD_REQUIRED } from '../../const';
import ApiService from '../../services/api-service';


function LibArticleEdit(props) {
  const { data, callBack } = props;
  const [title, setTitle] = useState(data?.Title);
  const [description, setDescription] = useState(data?.Description);
  const [order, setOrder] = useState(data?.Order);
  const [disabled, setDisabled] = useState(data?.disabled);
  const [validation, setValidation] = useState(new Map());
  useEffect(()=>{
    setTitle(data?.Title);
    setDescription(data?.Description);
    setOrder(data?.Order);
    setDisabled(data?.disabled);
  },[data]);
  const titleOnChange = (e) => {
    setTitle(e.target.value);
    validation.delete("title");
  }
  const descriptionOnChange = (e) => {
    setDescription(e.target.value)
  }
  const orderOnChange = (e) => {
    setOrder(e.target.value)
  }
  const disabledOnClick = () => {
    setDisabled(!disabled)
  }
  const validateFrom = () => {
    const newValidation = new Map();
    if (!title)
      newValidation.set("title", VALIDATION_FLD_REQUIRED)
    if (!order)
      newValidation.set("order", VALIDATION_FLD_REQUIRED)
    if (!description)
      newValidation.set("description", VALIDATION_FLD_REQUIRED)
    setValidation(newValidation);
    return newValidation.size === 0;
  }
  const submitOnClick = async (e) => {
    e.preventDefault();
    if (!validateFrom())
      return;
    const response = await ApiService.instance.postArticle(
      {
        Id: data?.Id,
        Title: title,
        Description: description,
        Order: order,
        Disabled: disabled
      }
    )
    callBack(response)
  }
  
  const deleteOnClick = async() => { 
    const response = await ApiService.instance.deleteArticle(data?.Id);
    callBack(response);
  }
  const backOnClick = () => { }
  return (
    <form className='article-lib-edit' onSubmit={submitOnClick}>
      <div className='card' style={{ width: "100%" }} >
        <div className='card-title'>
          <div style={{flexShrink:'0'}}>
            {data?.Id ??'new article'}
          </div>
          {data?.Id && <div className='article-card-tool'>
          <RABtn label='back' type='button' onClick={backOnClick} />
            <RABtn label='delete' type='button' onClick={deleteOnClick} />
          </div>} </div>
        <div className='card-body' style={{ gap: "20px" }}>

          <Inputfield type="text" label="Title" value={title} valueOnChange={titleOnChange} validation={validation.get('title')} />
          <Inputfield type="text" label="Description" value={description} valueOnChange={descriptionOnChange} validation={validation.get('description')} />
          <Inputfield type="text" label="Order" value={order} valueOnChange={orderOnChange} validation={validation.get('order')} />
          <InputCheckBox label="Disabled" value={disabled} valueOnChange={disabledOnClick} />
        </div>
        <div className='card-footer'>
          <div className='article-card-tool'>
            <RABtn label='submit' type='submit' onClick={submitOnClick} />
          </div>
        </div>
      </div>

    </form>
  );
}

export default LibArticleEdit;
